const page = document.querySelector('body');
let consentBtn;

const openModal = function () {
  const consent = localStorage.getItem('LTO_consentement');
  if (consent) return;

  const modal = `
    <div class="rgpd__modal__wrapper">
      <div class="rgpd__modal">
        <h4 class="rgpd__modal__title">LET’S TRAIN OUT / PROTECTION DES DONNEES</h4>

        <p class="rgpd__modal__text">
          <span>
            En poursuivant votre navigation, vous acceptez notre charte cookies, nos CGU et CGV, la détection des bloqueurs de publicité,
            dépôt de cookies et technologies similaires, tiers ou non, le croisement avec les données que vous avez fournies dans les
            formulaires du site afin d’améliorer votre expérience utilisateur.
          </span>
          <span>
            Cliquez sur le bouton pour donner votre consentement à ces opérations et profitez d'une expérience personnalisée.
          </span>
        </p>
        <button class="rgpd__consent__button">TOUT ACCEPTER</button>
      </div>
    </div>
  `;

  page.innerHTML += modal;
  consentBtn = document.querySelector('.rgpd__consent__button');
};

const saveConsent = (e) => {
  const consent = localStorage.getItem('LTO_consentement');
  if (consent) return;
  const rgpdModal = document.querySelector('.rgpd__modal__wrapper');

  localStorage.setItem('LTO_consentement', true);
  rgpdModal.style.display = 'none';
};

openModal();
  if (consentBtn) {
    consentBtn.addEventListener('click', saveConsent);
  }
